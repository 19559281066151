import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Quote from "./Quote"

const RandomTestimonial = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiTestimonial {
        edges {
          node {
            id
            testimonial
            name
            url
          }
        }
      }
    }
  `)

  const randomTestimonialData = data.allStrapiTestimonial.edges[Math.floor(Math.random()*data.allStrapiTestimonial.edges.length)];
  const testimonialData = randomTestimonialData.node
  const testimonial = testimonialData.testimonial ? testimonialData.testimonial : ""
  const name = testimonialData.name ? testimonialData.name : ""
  // const role = testimonialData.role ? testimonialData.role : ""
  const url = testimonialData.url ? testimonialData.url : ""

  // let author
  // if (role) {
  //   author = name + ", " + role
  // } else {
  const author = name
  // }

  return (
    <Quote
      quote={testimonial}
      author={author}
      url={url}
    />
  )
}

export default RandomTestimonial