import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Footer from "@components/Footer/Footer"
import SocialLinks from "@components/Nav/SocialLinks"
// import Tagline from "@components/About/Tagline"
import RandomTestimonial from "@components/Quotes/RandomTestimonial"
import Anchor from "@static/icons/anchor-with-wrap.svg"
import Tool from "@components/Nav/Tool"

export const query = graphql`
  query AboutQuery($id: Int) {
    strapiAbout(strapiId: { eq: $id }) {
      strapiId
      title
      subtitle
      content
      description
      websitetoolkit {
        name
        url
        logoURL
      }
    }
  }
`

const AboutPage =  ({ data }) => {
  const about = data.strapiAbout
  const title = about.title ? about.title : ""
  const description = about.description ? about.description : ""
  const subtitle = about.subtitle ? about.subtitle : ""
  const content = about.content ? about.content : ""

  const websitetoolkit = about.websitetoolkit

  const fontMainName = {
    fontFamily: 'Mulish'
  }

  // const centerImg = {
  //   display: 'block',
  //   marginLeft: 'auto',
  //   marginRight: 'auto'
  // }

  let toolkit = []

  if (websitetoolkit) {
    websitetoolkit.map(item => {
      const tool = (
        <Tool
          key={item.name}
          name={item.name}
          url={item.url}
          logoURL={item.logoURL}
        />
      )
      // console.log('tool: ', tool)
      toolkit.push(tool)
    })
  }

  return (
    <Layout>
      <main className="grid-content">
        <div className="grid-content-inner">
          <div id="about" className="grid-container page">
            <SEO
              title={title}
              description={description}
            />

            <Anchor />
            <h1>{title}</h1>
            {/*<img src={logo} alt="KL McGregor Technology Inc. logo" style={centerImg} />*/}
            <h2 style={fontMainName}>{subtitle}</h2>
            <ReactMarkdown children={content} />

            <RandomTestimonial />

            <SocialLinks />
            {/*<Tagline />*/}

            {toolkit &&
              <div className="toolkit">
                <h4>This website was built with ❤️ and the following tools:</h4>
                <div className="tools-wrapper">
                  {toolkit}
                </div>
              </div>
            }
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  )
}

export default AboutPage
