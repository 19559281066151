import React from "react"

const Tool = ({ name, url, logoURL }) => (
  <div key={name}>
    <a className="tool-logo" href={url} rel="noreferrer" target="_blank" title={name}>
      <img src={logoURL} alt={`${name} logo`} />
    </a>
  </div>
)

export default Tool